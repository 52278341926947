import { BusinessmanFormValues, ProfileType } from '@dltru/dfa-models'
import {
    FinancialPositionStep,
    IRoleFormStepProps,
    IStepper,
    PublicPersonStep,
    StepperC,
} from '@dltru/dfa-ui'
import { FC } from 'react'

import { UploadInput } from '@components/Form'

import { BankDetailsStep } from '../components/BankDetailsStep'
import { BusinessReputationStep } from '../components/BusinessReputationStep'
import { FinancialOperationsStep } from '../components/FinancialOperationsStep'
import { LicensesStep } from '../components/LicensesStep'
import { BasicInformationStep, BeneficiaryStep, BusinessInformationStep } from './steps'

const getTopText = (values: BusinessmanFormValues) => `
                    Укажите, пожалуйста, банковские реквизиты, получателем платежа по которым
                    является ИП ${values?.last_name} ${values?.first_name} ${values?.second_name}`

export const BusinessmanStepper: FC<
    Omit<IStepper<BusinessmanFormValues>, 'children'> & IRoleFormStepProps
> = ({ isEditAllow, ...props }) => (
    <StepperC {...props}>
        <BasicInformationStep isEditAllow={isEditAllow} />
        <BusinessInformationStep isEditAllow={isEditAllow} />
        <PublicPersonStep stepIndex={2} isEditAllow={isEditAllow} />
        <LicensesStep
            stepIndex={3}
            isEditAllow={isEditAllow}
            stepTitle="Сведения о лицензируемой деятельности"
        />
        <FinancialPositionStep stepIndex={4} isEditAllow={isEditAllow} UploadInput={UploadInput} />
        <BusinessReputationStep stepIndex={5} isEditAllow={isEditAllow} />
        <BeneficiaryStep isEditAllow={isEditAllow} />
        <BankDetailsStep stepIndex={7} isEditAllow={isEditAllow} getTopText={getTopText} />
        <FinancialOperationsStep stepIndex={8} isEditAllow={isEditAllow} profileType={ProfileType.BUSN} />
    </StepperC>
)
