import { ProfileType } from '@dltru/dfa-models'
import {
    AddressForm,
    FioForm,
    Form,
    IRoleFormStepProps,
    PersonalForm,
    SnilsInput,
    Step,
    StepperContext,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { InnInput } from '@components/Form'
import { DocumentForm } from '@components/Form/components/DocumentForm'
import { UploadInputForm } from '@components/Form/components/UploadInputForm'

const STEP_INDEX = 0

export const BasicInformationStep: FC<IRoleFormStepProps> = ({ isEditAllow }) => {
    const [form] = Form.useForm()
    const { editable, stepsTotal, currentStep, values } = useContext(StepperContext)
    const isEdit = useEditable(currentStep, STEP_INDEX, editable)
    const allowEdit =
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(STEP_INDEX)) &&
        isEditAllow

    return (
        <Step
            form={form}
            stepIndex={STEP_INDEX}
            stepTitle="Основные сведения"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <FioForm uneditable={isEdit} />
            <PersonalForm uneditable={isEdit} />
            <AddressForm
                uneditable={isEdit}
                group="main_address"
                addressTitle="Адрес регистрации"
            />
            <DocumentForm uneditable={isEdit} />
            <div className="formRow">
                <InnInput
                    uneditable={isEdit}
                    required
                    checkInnNotExistenceFor={!values?.id ? ProfileType.BUSN : undefined}
                />
            </div>
            <div className="formRow formRow--without-label">
                <UploadInputForm
                    name="inn_file"
                    uneditable={isEdit}
                    required
                    title="Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
            <div className="formRow">
                <SnilsInput uneditable={isEdit} required />
            </div>
            <div className="formRow formRow--without-label">
                <UploadInputForm
                    name="snils_file"
                    uneditable={isEdit}
                    required
                    title="Допустимый формат файлов pdf, png, jpeg, jpg, tiff. Общий вес не более 5 МБ"
                    maxSizeMb={5}
                    accept=".pdf,.png,.jpeg, .jpg, .tiff"
                />
            </div>
        </Step>
    )
}
